<template>
  <div class="wh-space-bottom">
    <template v-if="view_permission">
      <b-card>
        <edit-field-header
          :title="title"
          :show-back-btn="false"
          :sub-title="$t('device_raw_data.warning_message')"
          sub-title-class="text-danger"
        />
        <edit-field
          :fields="fields"
          :rowData="rowData"
          post-api="/callbell/DownloadTechRawData"
          finish-router="deviceRawData"
          :submitBtnText="$t('device_raw_data.export_data')"
          :submitAction="submitAction"
          @beforeSubmit="beforeSubmit"
        >
        </edit-field>
      </b-card>
    </template>
    <b-card v-else>{{ $t('permission.no_view') }}</b-card>
  </div>
</template>

<script>
import common from '@/common'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import ButtonPermission from '@/components/ButtonPermission'
import SlotBed from '@/views/group/SlotBed'
import ModalBed from '@/views/group/ModalBed'
import EditField from '@/components/EditField2'
import EditFieldHeader from '@/components/EditFieldHeader'
import fun from '@/views/group/fun.js'
import TableSearch from '@/components/TableSearch'

export default {
  name: 'Index',
  components: {
    ButtonPermission,
    flatPickr,
    vSelect,
    SlotBed,
    ModalBed,
    EditField,
    EditFieldHeader,
    TableSearch,
  },
  data() {
    return {
      title: common.getMenuName('callBellRawTech'),
      view_permission: common.checkPermission('CallBell_Menu_RawTech'),
      is_admin: common.isAdmin(),
      company_options: [],
      facility_options: [],
      show_report_type: 'all',
      time_option: [],

      // 表單的默認信息
      rowData: {
        // facilityID: common.getFacilityId(),
        // beds: []
      },

      fields: [
        {
          label: 'device_report.start_date',
          name: 'startDate',
          type: 'date',
          rule: 'required',
          config: {
            wrap: true,
            minDate: null,
            maxDate: this.$moment()
              .add(1, 'hours')
              .format('YYYY-MM-DD'),
          },
          default: this.$moment()
            .add(-6, 'days')
            .format('YYYY-MM-DD'),
          on_change: this.handleDateChange,
        },
        {
          label: 'device_report.end_date',
          name: 'endDate',
          type: 'date',
          rule: 'required',
          config: {
            wrap: true,
            minDate: null,
            maxDate: this.$moment()
              .add(1, 'hours')
              .format('YYYY-MM-DD'),
          },
          default: this.$moment()
            .add(1, 'hours')
            .format('YYYY-MM-DD'),
          on_change: this.handleDateChange,
        }
      ]
    }
  },

  mounted() {
    if (common.isAdmin()) {
      common.getSearchCompanyOptions('companyID', this)
      common.getSearchFacilityOptions('facilityID', common.getAdminCompanyId(), this)
    }
    // set default value from fields to forms
    this.fields.forEach((field) => {
      if (field.default !== undefined && field.name) {
        this.$set(this.rowData, field.name, field.default)
      }
    })
  },

  methods: {
    handleDateChange({dateType, dateStr}) {
      const fields = this.fields
      const startDateIndex = fields.findIndex(field => field.name === 'startDate')
      const endDateIndex = fields.findIndex(field => field.name === 'endDate')
      common.syncFieldsOfDateRange({
        dayRange: 30,
        changedDateType: dateType,
        changedDateStr: dateStr,
        vmDateWrapperObj: this.rowData,
        vmStartDateConfig: startDateIndex >= 0 ? fields[startDateIndex].config : undefined,
        vmEndDateConfig: endDateIndex >= 0 ? fields[endDateIndex].config : undefined,
      })
    },

    beforeSubmit: function (callback) {
      const res_1 = this.$refs.slot_bed ? this.$refs.slot_bed.buildData() : true
      callback(res_1)
    },

    submitAction: function (rowData, api, _finishRouter) {

      // check date
      const startMoment = this.$moment(`${this.rowData['startDate']}`)
      const endMoment = this.$moment(`${this.rowData['endDate']}`)
      if (endMoment.diff(startMoment) < 0) {
        common.showError('ReportConditionDateError')
        return false
      }

      common.apiDownloadData({
        url: api,
        method: 'post',
        timeout: 2 * 60 * 60 * 1000,
        data: {
          startDate: rowData.startDate,
          endDate: rowData.endDate,
          facilityID: rowData.facilityID,
          bedIDs: rowData.bedIDs,
        },
      })
    },

  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.reportWrapper > hr {
  margin: 2rem 0;
}

.reportWrapper:last-of-type > hr {
  display: none;
}
</style>

